// LogoutButton.js
import React, { useState } from 'react';
import axios from 'axios';
import './Logout.module.css'; // Ensure you import your styles
import { Button, Modal } from 'antd';
const apiUrl = process.env.REACT_APP_SERVER_URL;
const TOKEN = process.env.REACT_APP_JWT_TOKEN;
const LogoutButton = ({ setUser }) => {
    const [loading, setLoading] = useState(false);
    const [keepDataLoading, setKeepDataLoading] = useState(false);
    const [open, setOpen] = useState(false);
    
    console.log("url: ", apiUrl)
    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setKeepDataLoading(true);
        const loggedInUser = localStorage.getItem('user')
        let user = JSON.parse(loggedInUser)
        const headers = {
            'x-api-key': TOKEN,  // Example custom header
            'Content-Type': 'application/json'  // Example of setting content type
          };
        axios.post(`${apiUrl}/calendar/logout`, { id: user.id, keepData: false },{headers})
            .then((res) => {
                
                console.log('calendar token:', JSON.stringify(res))
                if (res.data.message) {
                    setKeepDataLoading(false)
                    alert(res.data.message);
                    localStorage.setItem('login', false);
                    setUser(localStorage.getItem('login'));
                    setKeepDataLoading(false)
                    setOpen(false);
                }
                return res
            })
            .catch((error) => {
                console.log('calendar token:', error)
                setLoading(false)
                alert(error);
                return error;

            });
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleNo = () => {
        setLoading(true);
        const loggedInUser = localStorage.getItem('user')
        let user = JSON.parse(loggedInUser)
        axios.post(`${apiUrl}/calendar/logout`, { id: user.id, keepData: true })
            .then((res) => {
                console.log('calendar token:', JSON.stringify(res))
                if (res.data.message) {
                    alert(res.data.message);
                    localStorage.setItem('login', false);
                    setUser(localStorage.getItem('login'))
                    setLoading(false);
                    setOpen(false);
                }
                return res
            })
            .catch((error) => {
                console.log('calendar token:', error)
                alert(error);
                return error;
            });
    }

    return (
        <div>
            <button
                type="button"
                style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    display: "inline-flex",
                    alignItems: "center",
                    color: "rgba(0, 0, 0, 0.54)",
                    boxShadow:
                        "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
                    padding: 0,
                    borderRadius: 2,
                    border: "1px solid transparent",
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: 20,
                    width: 236,
                    fontFamily: "Roboto, sans-serif"
                }}
                onClick={showModal}
            >
                <div
                    style={{
                        marginRight: 10,
                        background: "rgb(255, 255, 255)",
                        padding: 10,
                        borderRadius: 2
                    }}
                >
                    <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg">
                        <g fill="#000" fillRule="evenodd">
                            <path
                                d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                                fill="#EA4335"
                            />
                            <path
                                d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                                fill="#4285F4"
                            />
                            <path
                                d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                                fill="#FBBC05"
                            />
                            <path
                                d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                                fill="#34A853"
                            />
                            <path fill="none" d="M0 0h18v18H0z" />
                        </g>
                    </svg>
                </div>
                <span style={{ padding: "10px 10px 10px 0px", fontWeight: 500, color: '#4C4E52' }}>
                    Logout Google Calendar
                </span>
            </button>
            <Modal
                open={open}
                title="Data Deletion Confirmation"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button style={{color: 'white', backgroundColor: '#4C4E52'}} key="submit" type="primary" loading={ keepDataLoading} onClick={handleOk}>
                        Yes
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        style={{color: 'white', backgroundColor: '#4C4E52'}}
                        loading={loading}
                        onClick={handleNo}
                    >
                        No
                    </Button>
                ]}
            >
                <p style={{color: '#4C4E52'}} >Do you want to remove the Synced data from system?</p>
            </Modal>
            {/* {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Do you want to remove the linked data?</p>
                        <button className="confirm-btn" onClick={() => handleConfirm(false)}>Yes, remove data</button>
                        <button className="cancel-btn" onClick={() => handleConfirm(true)}>No, keep data</button>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default LogoutButton;
