// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Base_button_common__VkAti {\r\n    background-color: olivedrab;\r\n    color: white;\r\n    padding: 15px 30px;\r\n    border: none;\r\n    font-size: 22px;\r\n    border-radius: 15px;\r\n    margin: 1rem;\r\n    width: 90%;\r\n}\r\n.Base_error__l5VXf {\r\n    color: red;\r\n    text-align: left;\r\n    margin: auto;\r\n    font-size: 16px;\r\n    padding: 0px 1rem;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Components/Base.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,UAAU;AACd;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".button_common {\r\n    background-color: olivedrab;\r\n    color: white;\r\n    padding: 15px 30px;\r\n    border: none;\r\n    font-size: 22px;\r\n    border-radius: 15px;\r\n    margin: 1rem;\r\n    width: 90%;\r\n}\r\n.error {\r\n    color: red;\r\n    text-align: left;\r\n    margin: auto;\r\n    font-size: 16px;\r\n    padding: 0px 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_common": "Base_button_common__VkAti",
	"error": "Base_error__l5VXf"
};
export default ___CSS_LOADER_EXPORT___;
