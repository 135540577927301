import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from '@react-oauth/google';

const clientId='906585607272-aio348tm2hdqd21s7vb14dap2o9i1ikd.apps.googleusercontent.com'

ReactDOM.render(<GoogleOAuthProvider clientId={clientId} ><App /></GoogleOAuthProvider>, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
