// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_login__Jzkb0 {\r\n    width: 400px;\r\n    background: #fff;\r\n    border: 1px solid #dddfe2;\r\n    box-shadow: 0 2px 4px rgb(0 0 10 / 64%), 0 8px 16px rgb(0 10 0 / 34%);\r\n    border-radius: 8px;\r\n    padding: 1rem;\r\n    align-items: center;\r\n    text-align: center;\r\n}\r\n\r\n.Login_login__Jzkb0 > form input {\r\n    border-radius: 20px;\r\n    border: 2px solid lightgrey;\r\n    outline: none;\r\n    color: #1d2129;\r\n    margin: 2% 0;\r\n    width: 90%;\r\n    padding: 12px;\r\n    font-size: 16px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Components/Login/Login.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,qEAAqE;IACrE,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;IACb,cAAc;IACd,YAAY;IACZ,UAAU;IACV,aAAa;IACb,eAAe;AACnB","sourcesContent":[".login {\r\n    width: 400px;\r\n    background: #fff;\r\n    border: 1px solid #dddfe2;\r\n    box-shadow: 0 2px 4px rgb(0 0 10 / 64%), 0 8px 16px rgb(0 10 0 / 34%);\r\n    border-radius: 8px;\r\n    padding: 1rem;\r\n    align-items: center;\r\n    text-align: center;\r\n}\r\n\r\n.login > form input {\r\n    border-radius: 20px;\r\n    border: 2px solid lightgrey;\r\n    outline: none;\r\n    color: #1d2129;\r\n    margin: 2% 0;\r\n    width: 90%;\r\n    padding: 12px;\r\n    font-size: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "Login_login__Jzkb0"
};
export default ___CSS_LOADER_EXPORT___;
