import "./App.css";
import GoogleLoginComponent from "./Components/Google Login/google";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LogoutButton from './Components/Google Logout/Logout'



function App() {
  const [userstate, setUserState] = useState({});
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  useEffect(()=> {
    setUserLoggedIn(localStorage.getItem('login'))
    console.log(userLoggedIn, "login user info")
  },[])
  useEffect(() => {
    console.log("user", userstate)
  }, [userstate])

  const ProtectedRoute = ({ children }) => {
    if (localStorage?.getItem('user') === null ||
      localStorage?.getItem('user') === undefined ||
      localStorage?.getItem('user') === '') {
      return <Navigate to="/login" />
    }

    return children;
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute >
                {userLoggedIn === null || userLoggedIn === undefined || userLoggedIn === "false" ? <><GoogleLoginComponent user={userstate}  setUser= {setUserLoggedIn}/></> : <><LogoutButton setUser={setUserLoggedIn}/></>}
                {/* <div>
                  <GoogleLoginComponent user={userstate} />
                  <LogoutButton />
                </div> */}
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/login"
            element={<Login setUserState={setUserState} />}
          ></Route>
          <Route path="/signup" element={<Register />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
